<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">精品课程</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">订单列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="订单编号" class="searchboxItem ci-full">
              <span class="itemLabel">订单编号:</span>
              <el-input
                v-model="selectDate.orderSn"
                type="text"
                clearable
                size="small"
                placeholder="请输入订单编号"
              />
            </div>
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="selectDate.productName"
                type="text"
                clearable
                size="small"
                placeholder="请输入课程名称"
              />
            </div>
            <div title="订单状态" class="searchboxItem ci-full">
              <span class="itemLabel">订单状态:</span>
              <el-select
                      size="small"
                      v-model="selectDate.orderStatus"
                      clearable
                      placeholder="请选择订单状态"
                      style="width:100%"
              >
                <el-option
                        v-for="item in orderStatusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="下单时间" class="searchboxItem ci-full">
              <span class="itemLabel">下单时间:</span>
              <el-date-picker
                      v-model="selectDate.date"
                      type="daterange"
                      size="small"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
              />
            </div>
            <el-button class="bgc-bv" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              stripe
              :header-cell-style="tableHeader"
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="订单编号" align="left" show-overflow-tooltip prop="orderSn" />
              <el-table-column label="课程名称" align="left" show-overflow-tooltip prop="productName" />
              <el-table-column label="价格（元）" align="left" show-overflow-tooltip prop="productPrice" />
              <el-table-column label="实付金额（元）" align="left" show-overflow-tooltip prop="paymentMoney" />
                <el-table-column label="下单时间" align="left" show-overflow-tooltip prop="createTime" />
                <el-table-column
                        label="订单状态"
                        align="left"
                        show-overflow-tooltip
                >
                    <template slot-scope="scope">{{
                        scope.row.orderStatus == '1' ?'已完成':'未完成'
                        }}</template>
                </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "marketUserList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      authentication: "",
      authenticationList: [], //订单状态list
      selection: true,
      searchPhone: "",
      searchName: "",
      centerDialogVisible: false, //
      sessionObj: {},
      options: [],
        selectDate:{
            orderSn:'',
            productName:'',
            userName:'',
            mobile:'',
            startTime:'',
            orderStatus:'',
            date:''
        },
        orderStatusList:[
              {
          value: "2",
          label: "已完成",
        },
        {
          value: "1",
          label: "未完成",
        },
        {
          value: "3",
          label: "退款失败",
        },
        {
          value: "4",
          label: "已退款",
        },
        ]
    };
  },
  computed: {
    getuser() {
      let user = "";
      if (this.marketLeaderArr.length) {
        user = this.marketLeaderArr.find(
          el => el.adminId == this.dialogRightMarketUser
        ).fullname;
      }
      return user;
    }
  },
  mounted() {
      this.getAuthenticationList();
  },
  created() {
  },
  methods: {
      //订单状态
      getAuthenticationList() {
          const studylist = this.$setDictionary("AUTHENTICATION", "list");
          const list = [];
          for (const key in studylist) {
              list.push({
                  value: key,
                  label: studylist[key]
              });
          }
          this.authenticationList = list;
      },
    // 数组去重
    doRepeat(arr) {
      if (!Array.isArray(arr)) {
        return;
      }
      let checkArr = [];
      let newArr = [];
      for (let i = 0; i < arr.length; i++) {
        if (!checkArr.includes(arr[i].compId)) {
          checkArr.push(arr[i].compId);
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
      // 列表
      getData(pageNum = 1) {
          const params = {
              pageNum: pageNum,
              pageSize: this.pageSize,
          };
          if (this.selectDate.productName) {
              params.productName = this.selectDate.productName;
          }
          if (this.selectDate.orderSn) {
              params.orderSn = this.selectDate.orderSn;
          }
          if (this.selectDate.date && this.selectDate.date.length) {
              params.createTime = this.selectDate.date[0];
              params.endTime = this.selectDate.date[1];
          }
          if (this.selectDate.orderStatus) {
              params.orderStatus = this.selectDate.orderStatus;
          }
          this.doFetch({
                  url: "/order/main/order-main/pageList",
                  params,
                  pageNum
              } ,false,
              2);
      },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
  beforeRouteLeave: resetKeepAlive
};
</script>
<style lang="less">
.relation_jg_dialog {
  .el-dialog--center .el-dialog__body {
    padding: 25px 20px 30px;
  }
  .el-table--border {
    border-bottom: 1px solid #ebeef5;
    th {
      border-right: 1px solid #ebeef5;
    }
  }
  .table_box {
    width: 42%;
    .table_box_top {
      margin-bottom: 10px;
    }
    .list_top {
      padding: 15px;
      text-align: center;
      border: 1px solid #ebeef5;
      border-bottom: 0;
    }
    .list_search {
      border: 1px solid #ebeef5;
      border-top: 0;
      border-bottom: 0;
      padding: 0 10px 15px;
    }
  }
  // 左右按钮操作
  .btn_arrow {
    padding: 1.25rem;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .relation_page {
    .el-pagination__sizes {
      .el-select {
        top: -3px;
      }
    }
  }
}
</style>


